import React from 'react'
import styles from './styles/type2.module.scss'
import Image from 'next/image'

interface LogoListType2 {
  list: Array<{ logo: string; path: string }>
}

export default function Type2({ list }: LogoListType2) {
  return (
    <div className={styles.type2}>
      <ul className={styles.list_wrapper}>
        {list.map((item, index) => (
          <li key={index} className={styles.item}>
            <a>
              <img src={item.logo} alt={item.path} loading="lazy"/>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
